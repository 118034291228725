const state = {
  hospitals: [],
  hospital: {},
  employees: [],
  employee: {},
  employeeSchedules: [],
  employeeSchedule: {},
  employeeAssignments: [],
  shifts: [],
  services: [],
  service: {},
  serviceShifts: [],
  wards: [],
  ward: {},
  wardShifts: [],
  serviceEmployeeCount: null,
};

export default state;
