/* This File is for Breadcrumb Navigation */
<template>
  <v-breadcrumbs :items="breadCrumbs">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :disabled="item.name === $route.name"
        active-class="breadcrumb-text-active"
      >
        <router-link
          :to="{ name: item.name, params: item.params, query: item.query }"
          class="breadcrumb-text"
        >
          {{ $t(item.text) }}
        </router-link>
      </v-breadcrumbs-item>
    </template>
    <template v-slot:divider>
      <span style="color: #081734; font-size: 18px; font-weight: 600"> > </span>
      <!--      <v-icon color="#081734" size="16">mdi-chevron-right</v-icon>-->
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppBreadCrumbs",
  computed: {
    ...mapGetters({
      getBreadCrumbs: "app/getBreadCrumbs",
    }),
    breadCrumbs() {
      return this.getBreadCrumbs;
    },
  },
};
</script>

<style scoped>
.breadcrumb-text {
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #abb7cf;
}
.router-link-exact-active {
  color: #081734 !important;
}
.v-application ul,
.v-application ol {
  padding-left: 0;
}
.v-breadcrumbs {
  padding: 0;
}
</style>
