export const ROLES = {
  ADMIN: 0,
  HOSPITAL: 1,
  EMPLOYEE: 2,
};

export const _ROLES = {
  0: "ADMIN",
  1: "HOSPITAL",
  2: "EMPLOYEE",
};

export const ROLE_LEVELS = [
  { key: 0, value: "admin", name: "ADMIN", profile: "admin" },
  { key: 1, value: "hospital", name: "HOSPITAL", profile: "hospital-admin" },
  { key: 2, value: "employee", name: "EMPLOYEE", profile: "employee" },
];
