import Vue from "@/plugins/subscriber";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/router";
import store from "@/store";
import i18n from "@/plugins/i18n";
import "@/plugins/notifications";

import Moment from "moment";
import { extendMoment } from "moment-range";
import "@/assets/scss/main.scss";
import "@/assets/style/index.scss";

import { mask } from "vue-the-mask";
Vue.directive("mask", mask);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
import VCalendar from "v-calendar";

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, { componentPrefix: "vc" });
import AppButton from "@/components/AppButton";
import AppNavButton from "@/components/AppNavButton";
import AppPagination from "@/components/AppPagination";
import SuccessConfirmation from "@/components/SuccessConfirmation";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import ActionConfirmation from "@/components/ActionConfirmation";
import AppSimpleTable from "@/components/AppSimpleTable";
import AppExcelExport from "@/components/AppExcelExport";

Vue.component("AppButton", AppButton);
Vue.component("AppNavButton", AppNavButton);
Vue.component("AppPagination", AppPagination);
Vue.component("SuccessConfirmation", SuccessConfirmation);
Vue.component("DeleteConfirmation", DeleteConfirmation);
Vue.component("ActionConfirmation", ActionConfirmation);
Vue.component("AppSimpleTable", AppSimpleTable);
Vue.component("AppExcelExport", AppExcelExport);

const moment = extendMoment(Moment);
// Set Monday (1) as the first day of the week for the "en" locale (English).
moment.updateLocale("en", { week: { dow: 1 } });
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Vue.config.productionTip = false;
window.appEnv = process.env;

if (process.env.VUE_APP_DEVTOOLS === "true") {
  Vue.config.devtools = true; // Enable Vue DevTools in staging
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
