var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "", "content-class": "brd-radius" },
      model: {
        value: _vm.curValue,
        callback: function ($$v) {
          _vm.curValue = $$v
        },
        expression: "curValue",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { rounded: "xl" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pa-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-start": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs2: "" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/icons/success-add.svg"),
                              height: "50px",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "title-1",
                          attrs: { xs10: "", "align-self-center": "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")]
                      ),
                      _vm.description
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "justify-end": "" } },
                                [
                                  _c("V-flex", { attrs: { xs10: "" } }, [
                                    _c("span", { staticClass: "subtitle-2" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t(_vm.description)) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "justify-end": "" } },
                            [
                              _c(
                                "V-flex",
                                { attrs: { xs10: "" } },
                                [_vm._t("content")],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "py-4": "" } },
                        [_c("v-divider")],
                        1
                      ),
                      _c("v-flex", { attrs: { xs12: "", "text-right": "" } }, [
                        _vm.type !== "edit"
                          ? _c(
                              "span",
                              { staticClass: "px-4" },
                              [
                                _vm.showDuplicate
                                  ? _c("AppButton", {
                                      attrs: {
                                        color: "#A6AEBE",
                                        "custom-class": "pa-5",
                                        text: "",
                                      },
                                      on: { submit: _vm.duplicate },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "content",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "14px",
                                                      color: "#a6aebe",
                                                    },
                                                  },
                                                  [_vm._v(" mdi-content-copy ")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#a6aebe",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "words.duplicate"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3835840393
                                      ),
                                    })
                                  : _vm._e(),
                                _c("AppButton", {
                                  attrs: {
                                    color: "#A6AEBE",
                                    "custom-class": "pa-5",
                                    text: "",
                                  },
                                  on: { submit: _vm.cancel },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  size: "14px",
                                                  color: "#a6aebe",
                                                },
                                              },
                                              [_vm._v("mdi-plus")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#a6aebe",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "messages.add_another"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3344933429
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "span",
                          { staticClass: "pl-4" },
                          [
                            _c("AppButton", {
                              attrs: { "custom-class": "pa-5" },
                              on: { submit: _vm.submit },
                              scopedSlots: _vm._u([
                                {
                                  key: "content",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("buttons.done")) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }