<template>
  <v-app>
    <AppLoading />
    <router-view :key="$route.fullPath"></router-view>
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar.vue";
import { mapGetters } from "vuex";
import AppLoading from "@/components/AppLoading.vue";

export default {
  name: "App",
  components: {
    AppLoading,
    Snackbar,
  },

  computed: {
    ...mapGetters({
      loggedIn: "app/isLoggedIn",
    }),
  },
  mounted() {
    if (this.loggedIn) this.$store.dispatch("app/resetApp");
  },
  data: () => ({}),
};
</script>
<style>
body {
  border: 0;
  margin: 0;
}
#app {
  font-family: "Inter", sans-serif;
}
</style>
