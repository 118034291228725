const getters = {
  getHospitals: (state) => {
    return state.hospitals;
  },
  getEmployees: (state) => {
    return state.employees;
  },
  getEmployee: (state) => {
    return state.employee;
  },
  getShifts: (state) => {
    return state.shifts;
  },
  getServices: (state) => {
    return state.services;
  },
  getService: (state) => {
    return state.service;
  },
  getEmployeeSchedules: (state) => {
    return state.employeeSchedules;
  },
  getEmployeeSchedule: (state) => {
    return state.employeeSchedule;
  },
  getEmployeeAssignments: (state) => {
    return state.employeeAssignments;
  },
  getServiceShifts: (state) => {
    return state.serviceShifts;
  },
  getWards: (state) => {
    return state.wards;
  },
  getWard: (state) => {
    return state.ward;
  },
  getWardShifts: (state) => {
    return state.wardShifts;
  },
  getServiceEmployeeCount: (state) => {
    return state.serviceEmployeeCount;
  },
};

export default getters;
