var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.items.length < 1
        ? _c(
            "v-card",
            {
              staticClass: "app-border-color",
              attrs: { width: "100%", height: "30vh", outlined: "" },
            },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "justify-start": "", "fill-height": "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "subtitle-1",
                      attrs: {
                        xs12: "",
                        "text-center": "",
                        "align-self-center": "",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.emptyTitle)) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("table", { staticStyle: { "min-width": "100%" } }, [
            _c("thead", [
              _c(
                "tr",
                [
                  _vm._l(_vm.headings, function (heading, index) {
                    return [
                      _c(
                        "th",
                        {
                          key: index,
                          staticClass: "text-left",
                          style: heading.style ? heading.style : "",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(heading.name)) + " ")]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _c(
              "tbody",
              [
                _vm._l(_vm.items, function (item, index) {
                  return [
                    _c(
                      "tr",
                      {
                        key: "item-" + index,
                        staticStyle: {
                          "padding-top": "8px",
                          "padding-bottom": "8px",
                        },
                        style: item.rowStyle,
                      },
                      [
                        _vm._l(_vm.headings, function (heading, index2) {
                          return [
                            heading.type === "status"
                              ? _c(
                                  "td",
                                  { key: "item-status-" + index2 },
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: item[heading.value].color,
                                          outlined: "",
                                          small: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item[heading.value].label) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : heading.value !== "action"
                              ? _c("td", { key: "item-val-" + index2 }, [
                                  item[heading.value]
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(item[heading.value]) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [_vm._v(" - ")]),
                                ])
                              : heading.value === "action"
                              ? _c(
                                  "td",
                                  {
                                    key: "item-action-" + index2,
                                    staticClass: "action",
                                  },
                                  [
                                    heading.options.length
                                      ? [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "justify-start": "",
                                                "py-2": "",
                                              },
                                            },
                                            [
                                              _vm.enableView
                                                ? _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        "text-center": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: heading.view,
                                                              params: {
                                                                id: item.id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                size: "20",
                                                              },
                                                            },
                                                            [_vm._v("mdi-eye")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.enableEdit
                                                ? _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        "text-center": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: heading.edit,
                                                              params: {
                                                                id: item.id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/icons/pencil.svg"),
                                                              height: "20px",
                                                              alt: "edit",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.enableDelete
                                                ? _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        "text-center": "",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "cursor-pointer",
                                                        attrs: {
                                                          src: require("@/assets/icons/trash.svg"),
                                                          height: "20px",
                                                          alt: "delete",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._t("inlineAction", null, {
                                                data: { item, index },
                                              }),
                                            ],
                                            2
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._t("action", null, {
                                      data: { item, index },
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
      _c(
        "v-layout",
        { attrs: { wrap: "", "justify-start": "", "pt-4": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs8: "", sm6: "", md6: "", xl3: "", "text-left": "" } },
            [
              _c("v-pagination", {
                attrs: {
                  "total-visible": "6",
                  length: _vm.pagination.last_page,
                },
                model: {
                  value: _vm.paginate,
                  callback: function ($$v) {
                    _vm.paginate = $$v
                  },
                  expression: "paginate",
                },
              }),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            {
              staticClass: "pagination-result",
              attrs: {
                xs4: "",
                sm2: "",
                "align-self-center": "",
                "text-right": _vm.$vuetify.breakpoint.smAndUp,
              },
            },
            [
              _vm.pagination.total
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("words.result", {
                            result: `${_vm.pagination.from}-${_vm.pagination.to}`,
                            total: _vm.pagination.total,
                          })
                        ) +
                        " "
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("words.n_result", { n: 0 })) + " "
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }