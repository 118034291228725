import AuthService from "@/services/auth.service";
import Helpers from "@/libs/helper/components/helper-functions";
import { getStrLocal, removeLocal, setLocal } from "@/libs/local";
import UserService from "@/services/user.service";
import AppService from "@/services/app.service";
import { ROLES } from "@/constants/user-roles";
import { _SHIFTS } from "@/constants/shifts";
import moment from "moment";
import "moment-timezone";
// import { messaging, deleteToken } from "@/plugins/firebase";
const actions = {
  resetApp(context) {
    const locale = getStrLocal("lang");
    context.commit("setLang", locale);
    moment.locale(locale);
    context.dispatch("getQualifications");
    if (context.state.profile.role !== ROLES.ADMIN)
      context.dispatch("getDepartments", { per_page: 100, page: 1 });
    context.dispatch("getTimezones");
  },

  async login(context, payload) {
    const result = await AuthService.login(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        if (response.data.user.role !== ROLES.EMPLOYEE)
          context.dispatch("saveLogin", response.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async logout(context) {
    const data = {
      device_token: getStrLocal("fcm"),
    };
    let result;
    if (data.device_token) {
      result = await AuthService.logout(data);
    } else {
      result = await AuthService.logout();
    }
    return Helpers.checkResponse(result)
      .then((response) => {
        // deleteToken(messaging)
        //   .then(() => {
        //     console.log("FCM token removed.");
        //   })
        //   .catch((err) => {
        //     console.log("Error removing FCM token: ", err);
        //   });
        context.dispatch("saveLogout");
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  saveLogin(context, payload) {
    setLocal("lang", payload.user?.default_locale ?? "en");
    setLocal("token", payload.token);
    setLocal("profile", payload.user);
    context.commit("setLang", payload.user?.default_locale ?? "en");
    context.commit("setLogin", true);
    context.commit("setProfile", payload.user);
  },

  saveLogout(context) {
    removeLocal("token");
    removeLocal("profile");
    // removeLocal("fcm");
    context.commit("setLogin", false);
    context.commit("setProfile", {});
  },

  async getProfile(context) {
    const role = context.getters.getUserRole?.profile;
    const result = await UserService.getProfile(role);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setProfile", response.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
  async updateProfile(context, payload) {
    const data = {
      role: context.getters.getUserRole?.profile,
      ...payload,
    };
    const result = await UserService.updateProfile(data);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setProfile", response.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async changeLanguage() {
    const result = await UserService.changeUserLanguage();
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async changeTimezone(context, payload) {
    const result = await UserService.changeTimezone(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async changePassword(context, payload) {
    const result = await AuthService.changePassword(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async forgotPassword(context, payload) {
    const result = await AuthService.forgotPassword(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async resetPassword(context, payload) {
    const result = await AuthService.resetPassword(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getQualifications(context) {
    const result = await AppService.getQualifications();
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setQualifications", response.data.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getDepartments(context, payload) {
    const result = await AppService.getDepartments(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setDepartments", response.data.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getDepartment(context, payload) {
    const result = await AppService.getDepartment(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setDepartment", response.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getTimezones(context) {
    const result = await AppService.getTimezones();
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setTimezones", response.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getNotifications(context, payload) {
    const result = await AppService.getNotifications(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        const userTimeZone = context.state?.profile?.default_timezone ?? "";
        const timeZone = moment.tz(userTimeZone).utcOffset();
        const data = response.data.data.map((item) => {
          return {
            name:
              item.notification?.employee?.first_name +
              " " +
              item.notification?.employee?.last_name,
            employeeId: item.notification.employee.id,
            date: moment(item.notification.shift?.date).format("ddd DD"),
            shift: _SHIFTS[item.notification.shift?.shift_id],
            id: item.id,
            serviceId: item.notification?.shift?.service?.id,
            notificationTime: moment(item.created_at)
              .utc(timeZone)
              .format("MMM DD, YYYY HH:mm"),
            type: item.type,
          };
        });
        context.commit("setNotifications", data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
};

export default actions;
