import { getStrLocal } from "@/libs/local";

class ApiInterceptor {
  constructor(service) {
    this.service = service;
    this.queue = {};
  }

  setRequestConfig = (config) => {
    config = { ...config, ...config.config }; // merge configs

    const url = config.url;

    // get application language from local storage
    config.headers["Accept-Language"] = localStorage.getItem("lang") || "en";

    //  get access token from local storage for JWT
    const token = getStrLocal("token");

    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    //  prevent multiple requests for the same url
    this.queue[url] = url;
    return config;
  };
}

export default ApiInterceptor;
