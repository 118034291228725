import { checkLocal, getLocal, getStrLocal } from "@/libs/local";

const state = {
  sidebarItems: [],
  breadCrumbs: [],
  appMenu: false,
  isLoggedIn: checkLocal("token"),
  profile: getLocal("profile"),
  lang: "en",
  userType: "Admin",
  toasts: [],
  qualifications: [],
  departments: [],
  department: {},
  wards: [],
  ward: {},
  timezones: [],
  fetchCount: 0,
  isLoading: false,
  notify: false,
  notifications: [],
  fcmToken: getStrLocal("fcm"),
};

export default state;
