/*
 | =========================
 | This File is for authentication related routes
 | =========================
 */

const AuthRoutes = [
  {
    name: "login",
    path: "login",
    component: () => import("@/pages/Login"),
  },
  {
    name: "admin.login",
    path: "admin/login",
    component: () => import("@/pages/admin/Login"),
  },
  {
    name: "forgot-password",
    path: "forgot-password",
    component: () => import("@/pages/ForgotPassword"),
  },
  {
    name: "reset-password",
    path: "reset-password",
    component: () => import("@/pages/ResetPassword"),
  },
  // {
  //   name: "signup",
  //   path: "signup",
  //   component: () => import("@/pages/Authentication/Signup"),
  // },
];

export default AuthRoutes;
