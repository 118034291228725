import ApiService from "./api.service";

class EmployeeService {
  constructor() {
    this.request = ApiService;
  }

  getEmployeeList = ({
    per_page,
    search,
    page,
    gender,
    qualifications,
    department_id,
    is_active,
    sort_by,
    sort_by_dir,
  }) => {
    const params = {
      per_page,
      search,
      page,
      gender,
      qualifications,
      department_id,
      is_active,
      sort_by,
      sort_by_dir,
    };
    return this.request.get(`/hospital-admin/employee`, { params });
  };
}

export default new EmployeeService();
