var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-breadcrumbs", {
    attrs: { items: _vm.breadCrumbs },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "v-breadcrumbs-item",
              {
                attrs: {
                  disabled: item.name === _vm.$route.name,
                  "active-class": "breadcrumb-text-active",
                },
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "breadcrumb-text",
                    attrs: {
                      to: {
                        name: item.name,
                        params: item.params,
                        query: item.query,
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")]
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "divider",
        fn: function () {
          return [
            _c(
              "span",
              {
                staticStyle: {
                  color: "#081734",
                  "font-size": "18px",
                  "font-weight": "600",
                },
              },
              [_vm._v(" > ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }