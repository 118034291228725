var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("Sidebar"),
      _c(
        "v-main",
        [
          _c("Header"),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "fill-height": "" } },
            [_c("v-flex", { attrs: { xs12: "" } }, [_c("router-view")], 1)],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }