var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn-toggle",
    {
      attrs: { mandatory: "", dense: "", "background-color": "white" },
      model: {
        value: _vm.curValue.page,
        callback: function ($$v) {
          _vm.$set(_vm.curValue, "page", $$v)
        },
        expression: "curValue.page",
      },
    },
    [
      _vm._l(_vm.items, function (item, i) {
        return [
          _c("AppButton", {
            key: i,
            attrs: {
              "c-small": "",
              text: "",
              color: "#081734",
              label: _vm.getLabel(item),
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }