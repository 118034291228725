import ApiService from "./api.service";

class UserService {
  constructor() {
    this.request = ApiService;
  }

  changeUserLanguage = () => {
    return this.request.post(`/profile/locale`);
  };

  getUserLanguage = () => {
    return this.request.get(`/profile/locale`);
  };

  changeTimezone = (formData) => {
    return this.request.post(`/profile/timezone`, formData);
  };

  getProfile = (formdata) => {
    return this.request.get(`/${formdata}/profile`);
  };

  updateProfile = ({ role, ...formdata }) => {
    return this.request.patch(`/${role}/profile`, formdata);
  };
}

export default new UserService();
