import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

let options = {
  theme: {
    themes: {
      light: {
        primary: "#3278FF",
        secondary: "#1BAE79",
        accent: "#cddc39",
        error: "#E22B2B",
        warning: "#F0A027",
        info: "#00bcd4",
        success: "#1BAE79",
      },
      dark: {
        primary: "#3278FF",
        secondary: "#1BAE79",
        accent: "#cddc39",
        error: "#E22B2B",
        warning: "#F0A027",
        info: "#00bcd4",
        success: "#1BAE79",
      },
    },
  },
};
export default new Vuetify(options);
