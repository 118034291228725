var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "justify-end": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    transition: "slide-y-transition",
                    "offset-y": "",
                    left: "",
                    "nudge-bottom": "5",
                    "nudge-right": "80",
                    "max-width": "200px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ attrs, on }) {
                        return [
                          _c(
                            "div",
                            _vm._g(
                              _vm._b(
                                { staticClass: "text-no-wrap" },
                                "div",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm.profile.name
                                ? [
                                    _c("h4", [
                                      _vm._v(_vm._s(_vm.profile.name)),
                                    ]),
                                    _c("h6", [
                                      _vm._v(_vm._s(_vm.profile.email)),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-4", attrs: { rounded: "lg" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "justify-start": "" } },
                        [
                          _vm._l(_vm.menuOptions, function (item, i) {
                            return [
                              _c(
                                "v-flex",
                                {
                                  key: i,
                                  attrs: {
                                    xs12: "",
                                    "pb-2": "",
                                    "align-self-center": "",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: { name: item.route } } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { size: "20", color: "black" },
                                        },
                                        [_vm._v(_vm._s(item.icon))]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "value-1 small" },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { key: "a" + i, attrs: { xs12: "" } },
                                [_c("v-divider")],
                                1
                              ),
                            ]
                          }),
                          _c("v-flex", { attrs: { xs12: "", "pt-2": "" } }, [
                            _c(
                              "span",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: { click: _vm.logout },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { size: "20", color: "black" } },
                                  [_vm._v("mdi-logout")]
                                ),
                                _c("span", { staticClass: "value-1 small" }, [
                                  _vm._v(_vm._s(_vm.$t("buttons.logout"))),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }