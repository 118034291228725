/* This File is for {purpose} */

<template>
  <v-card width="500px">
    <v-layout wrap justify-start pa-4>
      <v-flex xs6>
        <span class="title-2">{{ $t("notifications.title") }}</span>
      </v-flex>
      <v-flex xs6 text-right>
        <AppButton
          outlined
          color="#C0CBE0"
          small
          width="25px"
          height="30px"
          custom-class="pa-0"
        >
          <template #content>
            <v-icon size="18" color="#000000">mdi-close</v-icon>
          </template>
        </AppButton>
      </v-flex>
      <v-flex xs12 py-4>
        <v-divider />
      </v-flex>
      <template v-for="(item, i) in notifications">
        <v-flex
          xs12
          px-4
          :key="'noti' + i"
          py-2
          @click="changeRoute(item)"
          class="pointer"
        >
          <v-avatar
            size="5"
            :color="item.type === 1 ? `success` : `error`"
          ></v-avatar>
          <span class="content-txt pl-2" v-if="item.type === 1">
            <span class="primary--text">{{ item.name }}</span>
            {{ $t("notifications.booked_slot", { date: item.date }) }}
          </span>
          <span class="content-txt pl-2" v-if="item.type === 2">
            <span class="primary--text">{{ item.name }}</span>
            {{ $t("notifications.reject_slot", { date: item.date }) }}
          </span>

          <span class="pl-2">
            <v-avatar color="#CFFAEA" size="20">
              <span style="color: #1bae79; font-size: 12px">
                {{ item.shift }}
              </span>
            </v-avatar>
          </span>
          <br />
          <span class="content-sub"> {{ item.notificationTime }} </span>
        </v-flex>
        <v-flex xs12 px-4 py-2 :key="i" v-if="i < notifications.length - 1">
          <v-divider />
        </v-flex>
      </template>
      <!--      <v-flex xs12 px-4 py-2 class="content-txt">-->
      <!--        {{ $t("notifications.view_all_noti") }}-->
      <!--      </v-flex>-->
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Notifications",
  computed: {
    ...mapGetters({
      notifications: "app/getNotifications",
    }),
  },
  methods: {
    changeRoute(payload) {
      this.$router.push({
        name: "service",
        params: { id: payload.serviceId },
      });
    },
  },
};
</script>

<style scoped></style>
