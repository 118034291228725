import Helpers from "@/libs/helper/components/helper-functions";
import HospitalService from "@/services/hospital.service";
import EmployeeService from "@/services/employee.service";

const actions = {
  async getHospitalList() {
    const result = await HospitalService.getHospitalList();
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async addHospital(context, payload) {
    const result = await HospitalService.addHospital(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async deleteHospital(context, payload) {
    const result = await HospitalService.deleteHospital(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async addEmployee(context, payload) {
    const result = await HospitalService.addEmployee(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getEmployeeList(context, payload) {
    const result = await EmployeeService.getEmployeeList(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setEmployees", response.data?.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getEmployee(context, payload) {
    const result = await HospitalService.getEmployee(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setEmployee", response.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async editEmployee(context, payload) {
    const result = await HospitalService.editEmployee(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async deleteEmployee(context, payload) {
    const result = await HospitalService.deleteEmployee(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async addDepartment(context, payload) {
    const result = await HospitalService.addDepartment(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
};

export default actions;
