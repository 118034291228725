/*
 * Translation plugin initialization
 * @type {VueI18n}
 * Main Config for i18n
 */

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
export default new VueI18n({
  locale: getLanguage(),
  fallbackLocale: "en",
  localeDir: "locales",
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});

/*
 * Parsing locale related messages & labels
 * @returns {{}}
 */
function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getLanguage() {
  return localStorage.getItem("lang") || "de";
}
