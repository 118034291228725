const mutations = {
  setHospitals: (state, payload) => {
    state.hospitals = payload;
  },
  setHospital: (state, payload) => {
    state.hospital = payload;
  },
  setEmployees: (state, payload) => {
    state.employees = payload;
  },
  setEmployee: (state, payload) => {
    state.employee = payload;
  },
  setShifts: (state, payload) => {
    state.shifts = payload;
  },
  setServices: (state, payload) => {
    state.services = payload;
  },
  setService: (state, payload) => {
    state.service = payload;
  },
  setEmployeeSchedules: (state, payload) => {
    state.employeeSchedules = payload;
  },
  setEmployeeSchedule: (state, payload) => {
    state.employeeSchedule = payload;
  },
  setEmployeeAssignments: (state, payload) => {
    state.employeeAssignments = payload;
  },
  setServiceShifts: (state, payload) => {
    state.serviceShifts = payload;
  },
  setWards: (state, payload) => {
    state.wards = payload;
  },
  setWard: (state, payload) => {
    state.ward = payload;
  },
  setWardShifts: (state, payload) => {
    state.wardShifts = payload;
  },
  setServiceEmployeeCount: (state, payload) => {
    state.serviceEmployeeCount = payload;
  },
};

export default mutations;
