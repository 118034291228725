/* This File is for {purpose} */

<template>
  <div>
    <v-card
      width="100%"
      height="30vh"
      outlined
      class="app-border-color"
      v-if="items.length < 1"
    >
      <v-layout wrap justify-start fill-height>
        <v-flex xs12 text-center align-self-center class="subtitle-1">
          {{ $t(emptyTitle) }}
        </v-flex>
      </v-layout>
    </v-card>
    <table v-else style="min-width: 100%">
      <thead>
        <tr>
          <template v-for="(heading, index) in headings">
            <th
              class="text-left"
              :style="heading.style ? heading.style : ''"
              :key="index"
            >
              {{ $t(heading.name) }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in items">
          <tr
            :key="'item-' + index"
            style="padding-top: 8px; padding-bottom: 8px"
            :style="item.rowStyle"
          >
            <template v-for="(heading, index2) in headings">
              <td
                v-if="heading.type === 'status'"
                :key="'item-status-' + index2"
              >
                <v-chip :color="item[heading.value].color" outlined small>
                  {{ item[heading.value].label }}
                </v-chip>
              </td>
              <td
                :key="'item-val-' + index2"
                v-else-if="heading.value !== 'action'"
              >
                <span v-if="item[heading.value]">
                  {{ item[heading.value] }}
                </span>
                <span v-else> - </span>
              </td>
              <td
                v-else-if="heading.value === 'action'"
                :key="'item-action-' + index2"
                class="action"
              >
                <template v-if="heading.options.length">
                  <v-layout wrap justify-start py-2>
                    <v-flex text-center v-if="enableView">
                      <router-link
                        :to="{ name: heading.view, params: { id: item.id } }"
                      >
                        <v-icon color="primary" size="20">mdi-eye</v-icon>
                      </router-link>
                    </v-flex>
                    <v-flex text-center v-if="enableEdit">
                      <router-link
                        :to="{ name: heading.edit, params: { id: item.id } }"
                      >
                        <img
                          src="@/assets/icons/pencil.svg"
                          height="20px"
                          alt="edit"
                        />
                      </router-link>
                    </v-flex>
                    <v-flex text-center v-if="enableDelete">
                      <img
                        src="@/assets/icons/trash.svg"
                        height="20px"
                        alt="delete"
                        class="cursor-pointer"
                        @click="deleteItem(item)"
                      />
                    </v-flex>
                    <slot name="inlineAction" :data="{ item, index }"></slot>
                  </v-layout>
                </template>
                <slot name="action" :data="{ item, index }"></slot>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
    <v-layout wrap justify-start pt-4>
      <v-flex xs8 sm6 md6 xl3 text-left>
        <v-pagination
          v-model="paginate"
          total-visible="6"
          :length="pagination.last_page"
        />
      </v-flex>
      <v-spacer />
      <v-flex
        xs4
        sm2
        align-self-center
        :text-right="$vuetify.breakpoint.smAndUp"
        class="pagination-result"
      >
        <span v-if="pagination.total">
          {{
            $t("words.result", {
              result: `${pagination.from}-${pagination.to}`,
              total: pagination.total,
            })
          }}
        </span>
        <span v-else> {{ $t("words.n_result", { n: 0 }) }} </span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "AppSimpleTable",
  props: {
    headings: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: false,
    },
    emptyTitle: {
      type: String,
      required: false,
    },
    pagination: {
      type: Object,
      required: false,
    },
  },
  computed: {
    paginate: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.$emit("paginate", val);
      },
    },
    enableView() {
      return this.headings
        .find((item) => {
          return item.value === "action";
        })
        .options.includes("view");
    },
    enableEdit() {
      return this.headings
        .find((item) => {
          return item.value === "action";
        })
        .options.includes("edit");
    },
    enableDelete() {
      return this.headings
        .find((item) => {
          return item.value === "action";
        })
        .options.includes("delete");
    },
  },
  methods: {
    deleteItem(item) {
      this.$emit("delete", item);
    },
  },
};
</script>

<style scoped lang="scss">
table {
  border: 1px solid #c0cbe0;
  border-collapse: separate;
  border-left: 0;
  border-radius: 7px;
  border-spacing: 0;
}
thead {
  display: table-header-group;
  text-align: center;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th,
td {
  padding: 5px 4px 5px 40px;
  border-left: 1px solid #c0cbe0;
  &.action {
    padding: 5px 4px;
  }
}
td {
  border-top: 1px solid #c0cbe0;
}
thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-radius: 7px 0 0 0;
}
thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 7px;
}
th {
  padding: 15px 4px 15px 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}
td {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #081734;
}
</style>
