var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AppButton", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.$t("table.export_data")) + " ")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }