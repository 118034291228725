<template>
  <div>
    <v-layout wrap justify-end>
      <v-flex xs12>
        <v-menu
          transition="slide-y-transition"
          offset-y
          left
          nudge-bottom="5"
          nudge-right="80"
          max-width="200px"
        >
          <template v-slot:activator="{ attrs, on }">
            <div v-bind="attrs" v-on="on" class="text-no-wrap">
              <template v-if="profile.name">
                <h4>{{ profile.name }}</h4>
                <h6>{{ profile.email }}</h6>
              </template>
            </div>
          </template>
          <v-card class="pa-4" rounded="lg">
            <v-layout wrap justify-start>
              <template v-for="(item, i) in menuOptions">
                <v-flex xs12 pb-2 :key="i" align-self-center>
                  <router-link :to="{ name: item.route }">
                    <v-icon size="20" color="black">{{ item.icon }}</v-icon>
                    <span class="value-1 small"> {{ item.name }} </span>
                  </router-link>
                </v-flex>
                <v-flex xs12 :key="'a' + i">
                  <v-divider></v-divider>
                </v-flex>
              </template>
              <v-flex xs12 pt-2>
                <span @click="logout" style="cursor: pointer">
                  <v-icon size="20" color="black">mdi-logout</v-icon>
                  <span class="value-1 small">{{ $t("buttons.logout") }}</span>
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getProfile: "app/getProfile",
    }),
    menuOptions() {
      return [
        {
          name: this.$t("pages.settings.title"),
          route: "settings",
          icon: "mdi-cog",
        },
      ];
    },
    profile() {
      return this.getProfile;
    },
  },
  methods: {
    logout() {
      this.$store
        .dispatch("app/logout")
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(() => {});
    },
  },
};
</script>
