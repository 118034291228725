import ApiService from "./api.service";

class HospitalService {
  constructor() {
    this.request = ApiService;
  }

  getHospitalList = () => {
    return this.request.get(`/admin/hospital`);
  };

  getHospital = (id) => {
    return this.request.get(`/admin/hospital/${id}`);
  };

  addHospital = (formData) => {
    return this.request.post(`/admin/hospital`, formData);
  };

  editHospital = ({ id, ...formData }) => {
    return this.request.patch(`/admin/hospital/${id}`, formData);
  };

  deleteHospital = (id) => {
    return this.request.delete(`/admin/hospital/${id}`);
  };

  uploadEmployees = (formData) => {
    console.log(formData);
    return this.request.post(`/hospital-admin/employee-import`, formData, {
      contentType: "multipart/form-data",
    });
  };

  addEmployee = (formData) => {
    return this.request.post(`/hospital-admin/employee`, formData);
  };

  editEmployee = ({ id, ...formData }) => {
    return this.request.patch(`/hospital-admin/employee/${id}`, formData);
  };

  deleteEmployee = (id) => {
    return this.request.delete(`/hospital-admin/employee/${id}`);
  };

  restoreEmployee = (id) => {
    return this.request.get(`/hospital-admin/employee-restore/${id}`);
  };

  getEmployeeSchedules = ({
    from,
    to,
    qualification_id,
    search,
    is_active,
    per_page,
    page,
  }) => {
    const params = {
      from,
      to,
      qualification_id,
      search,
      is_active,
      per_page,
      page,
    };
    return this.request.get(`/hospital-admin/employee-schedules`, { params });
  };

  getEmployeeSchedule = (id) => {
    return this.request.get(`/hospital-admin/employee-schedules/${id}`);
  };

  getEmployee = (id) => {
    return this.request.get(`/hospital-admin/employee/${id}`);
  };

  addDepartment = (formData) => {
    return this.request.post(`/hospital-admin/department`, formData);
  };

  getDepartment = (id) => {
    return this.request.get(`/hospital-admin/department/${id}`);
  };

  editDepartment = ({ id, ...formData }) => {
    return this.request.patch(`/hospital-admin/department/${id}`, formData);
  };

  deleteDepartment = (id) => {
    return this.request.delete(`/hospital-admin/department/${id}`);
  };

  getShifts = () => {
    return this.request.get(`/hospital-admin/shifts`);
  };

  editShifts = (formData) => {
    return this.request.patch(`/hospital-admin/shifts`, formData);
  };

  addService = (formData) => {
    return this.request.post(`/hospital-admin/service`, formData);
  };

  getServiceList = ({
    per_page,
    search,
    page,
    type,
    from,
    to,
    ward_id,
    sort_by,
    sort_by_dir,
  }) => {
    const params = {
      per_page,
      search,
      page,
      type,
      from,
      to,
      ward_id,
      sort_by,
      sort_by_dir,
    };
    return this.request.get(`/hospital-admin/service`, { params });
  };
  getServiceShifts = ({
    per_page,
    page,
    type,
    from,
    to,
    id,
    sort_by,
    sort_by_dir,
  }) => {
    const params = { per_page, page, type, from, to, sort_by, sort_by_dir };
    return this.request.get(`/hospital-admin/service-shifts/${id}`, { params });
  };

  getService = (id) => {
    return this.request.get(`/hospital-admin/service/${id}`);
  };

  deleteService = (id) => {
    return this.request.delete(`/hospital-admin/service/${id}`);
  };

  getEmployeeAssignments = ({
    per_page,
    page,
    type,
    from,
    to,
    id,
    ward_id,
    sort_by,
    sort_dir,
    download,
  }) => {
    const params = {
      per_page,
      page,
      type,
      from,
      to,
      ward_id,
      sort_by,
      sort_dir,
      download,
    };
    if (!params.download) {
      delete params.download;
    }
    if (params.download) {
      return this.request.get(`/hospital-admin/employee-shifts/${id}`, {
        params,
        responseType: "blob",
      });
    }
    return this.request.get(`/hospital-admin/employee-shifts/${id}`, {
      params,
    });
  };

  getWards = ({ per_page, page }) => {
    const params = { per_page, page };
    return this.request.get(`/hospital-admin/ward`, { params });
  };

  addWard = (formData) => {
    return this.request.post(`/hospital-admin/ward`, formData);
  };

  getWard = (id) => {
    return this.request.get(`/hospital-admin/ward/${id}`);
  };

  editWard = ({ id, ...formData }) => {
    return this.request.patch(`/hospital-admin/ward/${id}`, formData);
  };

  deleteWard = (id) => {
    return this.request.delete(`/hospital-admin/ward/${id}`);
  };

  deleteShift = (id) => {
    return this.request.delete(`/hospital-admin/service-shift/${id}`);
  };

  activateShift = (id) => {
    return this.request.post(`/hospital-admin/activate-service-shift/${id}`);
  };

  deactivateShift = (id) => {
    return this.request.delete(
      `/hospital-admin/deactivate-service-shift/${id}`
    );
  };

  getWardShifts = ({
    per_page,
    page,
    status,
    from_date,
    to_date,
    sort_by,
    sort_by_dir,
    ward_id,
    download,
  }) => {
    const params = {
      per_page,
      page,
      status,
      from_date,
      to_date,
      sort_by,
      sort_by_dir,
      ward_id,
      download,
    };
    if (!params.download) {
      delete params.download;
    }
    if (params.download) {
      return this.request.get(`/hospital-admin/employee-shifts`, {
        params,
        responseType: "blob",
      });
    }
    return this.request.get(`/hospital-admin/employee-shifts`, { params });
  };
  serviceEmployeeCount = ({
    gender_id,
    qualification_id,
    department_id,
    language_id,
    start_date,
    end_date,
    ward_id,
    shift_id,
  }) => {
    const params = {
      gender_id,
      qualification_id,
      department_id,
      language_id,
      start_date,
      end_date,
      ward_id,
      shift_id,
    };
    return this.request.get(`/hospital-admin/employee-count`, { params });
  };
}

export default new HospitalService();
