/* This File is for {purpose} */

<template>
  <v-app-bar height="60px" flat app>
    <v-layout wrap justify-start fill-height px-4>
      <v-flex xs1 align-self-center v-if="$vuetify.breakpoint.smAndDown">
        <v-icon size="20" @click="showMenu">mdi-menu</v-icon>
      </v-flex>
      <v-flex
        xs9
        sm8
        align-self-center
        text-left
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <AppBreadCrumbs />
      </v-flex>
      <v-spacer />
      <v-flex xs1 align-self-center text-right>
        <v-menu nudge-bottom="10" offset-y rounded="xl">
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              :src="notify.icon"
              :height="notify.size"
              contain
              @click="showNotification"
            />
          </template>
          <Notifications ref="notifications" />
        </v-menu>
      </v-flex>
      <v-flex xs6 sm3 lg2 align-self-center text-right>
        <AccountMenu />
      </v-flex>
    </v-layout>
  </v-app-bar>
</template>

<script>
import AppBreadCrumbs from "@/layouts/Partials/AppBreadCrumbs";
import Notifications from "@/layouts/Partials/Notifications";
import AccountMenu from "@/layouts/Partials/AccountMenu.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Header",
  components: {
    AccountMenu,
    Notifications,
    AppBreadCrumbs,
  },
  data: () => ({
    showBreadcrumb: false,
    showSideBar: false,
    pagination: {
      per_page: 3,
      page: 1,
      last_page: 1,
      total: 0,
      from: 0,
      to: 0,
    },
  }),
  computed: {
    ...mapGetters({
      getNotify: "app/getNotify",
    }),
    notify() {
      if (this.getNotify) this.getNotifications(this.pagination);
      return this.getNotify
        ? { icon: this.imageRenderer("notification-alert"), size: 23 }
        : { icon: this.imageRenderer("notification"), size: 18 };
    },
  },
  mounted() {
    this.getNotifications(this.pagination);
  },
  methods: {
    ...mapActions({
      getNotifications: "app/getNotifications",
    }),
    ...mapMutations({
      setNotify: "app/setNotify",
    }),
    showMenu() {
      this.showSideBar = !this.showSideBar;
      this.$store.commit("app/setAppMenu", !this.showSideBar);
    },
    showNotification() {
      this.setNotify(false);
      this.getNotifications(this.pagination);
    },
    imageRenderer(item) {
      return require(`@/assets/icons/${item}.svg`);
    },
  },
};
</script>

<style scoped>
header {
  border: 0;
  border-bottom: 1px solid #dddddd !important;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffffff;
}
</style>
