/*
 | ============================================
 | This File is for all common helper functions
 | ============================================
 */
import axios from "axios";
import store from "@/store";
import router from "@/router";
export default {
  /*
   * Response global handler
   * @param response
   * @param showToast
   * @returns {Promise<never>|Promise<unknown>}
   */
  checkResponse(response, showToast = false) {
    if (response) {
      switch (response.status) {
        case 200:
        case 201:
          if (showToast) {
            this.toast(
              "Success",
              (response.data &&
                response.data.result &&
                response.data.result.message) ??
                "Successfully updated"
            );
          }
          return Promise.resolve(response.data);

        default:
          switch (response.code) {
            case "ERR_NETWORK":
              router.push({ name: "error", params: { code: "500" } });
              break;
          }
          switch (response.response.status) {
            case 401:
              store.dispatch("app/saveLogout").then(() => {
                location.pathname = "login";
              });
              return Promise.reject(response.data);
            case 403:
              this.toast(
                "danger",
                response.data?.message ?? "Unauthorized",
                "Error"
              );
              return Promise.reject(response.data);
            default:
              if (axios.isCancel(response)) {
                return Promise.reject(response);
              } else {
                this.toast(
                  "danger",
                  response.response?.data?.result?.message ??
                    "The Request Failed! Please try again.",
                  "Error"
                );
                return Promise.reject(response);
              }
          }
      }
    } else {
      if (!axios.isCancel(response)) {
        this.toast("danger", "Something went wrong", "Error");
      }
      return Promise.reject(response ?? {});
    }
  },
  /*
   * Toast initiator
   * @param type, message, title, messageParam
   */
  toast(type = "success", message = null, title = null, messageParam = {}) {
    // this.$emit("toaster", { type, message, title, messageParam });
    console.log(type, message, title, messageParam);
  },
  /*
   *
   * @param payload
   */
  setPagination(payload) {
    return {
      page: payload.current_page,
      per_page: payload.per_page,
      last_page: payload.last_page,
      total: payload.total,
      from: payload.from,
      to: payload.to,
    };
  },
  /*
   * File auto download with blob response
   * @param blobResponse
   * @param filename
   */
  fileDownloadWithBlob(blobResponse, filename){
    const url = window.URL.createObjectURL(blobResponse);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
};
