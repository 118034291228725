/* This File is for {purpose} */

<template>
  <div>
    <template v-for="(item, i) in getToasts">
      <v-snackbar
        v-model="item.show"
        :color="getColor(item)"
        bottom
        right
        :key="'toast-' + i"
      >
        {{ item.message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="item.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Snackbar",
  computed: {
    ...mapGetters({
      toasts: "app/getToasts",
    }),
    getToasts: {
      get() {
        return this.toasts;
      },
      set(val) {
        this.$store.commit("app/setToasts", val);
      },
    },
  },
  methods: {
    getColor(item) {
      if (item.type)
        switch (item.type) {
          case "success":
            return "success";

          case "warning":
            return "warning";

          case "error":
            return "error";

          default:
            return "primary";
        }
      else return "primary";
    },
  },
};
</script>

<style scoped></style>
