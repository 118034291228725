var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { width: "500px" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "justify-start": "", "pa-4": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("span", { staticClass: "title-2" }, [
              _vm._v(_vm._s(_vm.$t("notifications.title"))),
            ]),
          ]),
          _c(
            "v-flex",
            { attrs: { xs6: "", "text-right": "" } },
            [
              _c("AppButton", {
                attrs: {
                  outlined: "",
                  color: "#C0CBE0",
                  small: "",
                  width: "25px",
                  height: "30px",
                  "custom-class": "pa-0",
                },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(
                          "v-icon",
                          { attrs: { size: "18", color: "#000000" } },
                          [_vm._v("mdi-close")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "py-4": "" } },
            [_c("v-divider")],
            1
          ),
          _vm._l(_vm.notifications, function (item, i) {
            return [
              _c(
                "v-flex",
                {
                  key: "noti" + i,
                  staticClass: "pointer",
                  attrs: { xs12: "", "px-4": "", "py-2": "" },
                  on: {
                    click: function ($event) {
                      return _vm.changeRoute(item)
                    },
                  },
                },
                [
                  _c("v-avatar", {
                    attrs: {
                      size: "5",
                      color: item.type === 1 ? `success` : `error`,
                    },
                  }),
                  item.type === 1
                    ? _c("span", { staticClass: "content-txt pl-2" }, [
                        _c("span", { staticClass: "primary--text" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("notifications.booked_slot", {
                                date: item.date,
                              })
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  item.type === 2
                    ? _c("span", { staticClass: "content-txt pl-2" }, [
                        _c("span", { staticClass: "primary--text" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("notifications.reject_slot", {
                                date: item.date,
                              })
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "span",
                    { staticClass: "pl-2" },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { color: "#CFFAEA", size: "20" } },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#1bae79",
                                "font-size": "12px",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.shift) + " ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("span", { staticClass: "content-sub" }, [
                    _vm._v(" " + _vm._s(item.notificationTime) + " "),
                  ]),
                ],
                1
              ),
              i < _vm.notifications.length - 1
                ? _c(
                    "v-flex",
                    { key: i, attrs: { xs12: "", "px-4": "", "py-2": "" } },
                    [_c("v-divider")],
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }