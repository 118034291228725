const mutations = {
  setHospitals: (state, payload) => {
    state.hospitals = payload;
  },
  setHospital: (state, payload) => {
    state.hospital = payload;
  },
  setEmployees: (state, payload) => {
    state.employees = payload;
  },
  setEmployee: (state, payload) => {
    state.employee = payload;
  },
};

export default mutations;
