import state from "./state";

import mutations from "./mutations";

import getters from "./getters";

import actions from "./actions";

const hospital = {
  namespaced: true,
  state: () => state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default hospital;
