import { ROLE_LEVELS } from "@/constants/user-roles";

const getters = {
  getSidebarItems: (state) => {
    return state.sidebarItems;
  },
  getBreadCrumbs: (state) => {
    return state.breadCrumbs;
  },
  getAppMenu: (state) => {
    return state.appMenu;
  },
  isLoggedIn: (state) => {
    return state.isLoggedIn;
  },
  getProfile: (state) => {
    return state.profile;
  },
  getLang: (state) => {
    return state.lang;
  },
  getToasts: (state) => {
    return state.toasts;
  },
  getUserRole: (state) => {
    return ROLE_LEVELS.find((item) => {
      return item.key === state.profile.role;
    });
  },
  getQualifications: (state) => {
    return state.qualifications;
  },
  getDepartments: (state) => {
    return state.departments;
  },
  getDepartment: (state) => {
    return state.department;
  },
  getTimezones: (state) => {
    return state.timezones;
  },
  getLoading: (state) => {
    return state.isLoading;
  },
  getNotify: (state) => {
    return state.notify;
  },
  getNotifications: (state) => {
    return state.notifications;
  },
  getFcmToken: (state) => {
    return state.fcmToken;
  },
};

export default getters;
