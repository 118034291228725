import axios from "axios";
import ApiInterceptor from "@/services/interceptors/api.interceptor";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
import store from "@/store";

class ApiService {
  constructor() {
    this.service = axios.create();
    this.interceptor = new ApiInterceptor(this.service);

    this.service.interceptors.request.use(this.interceptor.setRequestConfig);
    this.service.interceptors.request.use(
      (config) => {
        if (!config?.config?.params?.noLoading)
          store.commit("app/setLoading", true);
        return config;
      },
      (error) => {
        store.commit("app/setLoading", false);
        return error;
      }
    );

    this.service.interceptors.response.use(
      (response) => {
        store.commit("app/setLoading", false);
        return response;
      },
      (error) => {
        store.commit("app/setLoading", false);
        return error;
      }
    );
  }

  request = (method, url, data = {}, config = {}) => {
    return this.service({ method, url, data, config });
  };

  get = (url, data = {}, config = {}) => {
    return this.request("GET", url, config, data);
  };

  post = (url, data = {}, config = {}) => {
    return this.request("POST", url, data, config);
  };

  put = (url, data, config = {}) => {
    return this.request("PUT", url, data, config);
  };

  patch = (url, data, config = {}) => {
    return this.request("PATCH", url, data, config);
  };

  delete = (url, config = {}) => {
    return this.request("DELETE", url, {}, config);
  };
}

export default new ApiService();
