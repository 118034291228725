/* This File is for Dashboard Layout */

<template>
  <v-app>
    <Sidebar />
    <v-main>
      <Header />
      <v-layout wrap justify-start fill-height>
        <v-flex xs12>
          <router-view></router-view>
        </v-flex>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from "@/layouts/Partials/Sidebar";
import Header from "@/layouts/Partials/Header";

export default {
  name: "DashboardLayout",
  components: {
    Header,
    Sidebar,
  },
};
</script>

<style scoped></style>
