import {
  messaging,
  onMessage,
  getToken,
  cloudMessagingKey,
} from "@/plugins/firebase";
import store from "@/store";
import { setLocal } from "@/libs/local";

// Check if the device is iOS
const isIOSDevice = navigator.userAgent.match(/iPhone|iPad|iPod/i);
if (isIOSDevice) {
  console.log("iOS device, Firebase Notifications not supported here");
} else {
  // Handle incoming messages
  onMessage(messaging, () => {
    store.commit("app/setNotify", true);
  });

  // Request notification permission
  Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        // Get FCM token
        return getToken(messaging, { vapidKey: cloudMessagingKey });
      } else {
        console.log("Notification permission denied.");
        return null;
      }
    })
    .then((token) => {
      if (token) {
        store.commit("app/setFcmToken", token);
        setLocal("fcm", token);
      } else {
        console.log("No registration token available.");
      }
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. ", err);
    });
}

// Register service worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/firebase-messaging-sw.js").catch((err) => {
    console.error("Service Worker registration failed: ", err);
  });
}

export { getToken };
