/* This File is for {purpose} */

<template>
  <v-btn-toggle
    v-model="curValue.page"
    mandatory
    dense
    background-color="white"
  >
    <template v-for="(item, i) in items">
      <AppButton
        c-small
        text
        color="#081734"
        :label="getLabel(item)"
        :key="i"
      />
    </template>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "AppToggleButton",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    items: {
      type: Array,
      required: false,
      default: () => {
        return [1, 2, 3, 4];
      },
    },
  },
  computed: {
    curValue: {
      get() {
        return this.value ?? {};
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getLabel(item) {
      return String(item?.label ?? item);
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn-toggle {
  border-radius: 7px;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: #c0cbe0 !important;
}
.v-btn--active::before,
.v-btn--active:hover::before,
.v-btn--active {
  opacity: 1 !important;
  background-color: #dce8ff;
}
.v-btn-toggle > .v-btn.v-btn {
  opacity: 1;
}
.v-btn:focus::before {
  opacity: 0;
}
.v-btn:hover::before {
  background-color: #dce8ff;
  opacity: 0.34;
}
</style>
