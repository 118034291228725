/* This File is for {purpose} */

<template>
  <v-btn
    :color="color"
    @click="submit"
    :ripple="false"
    class="custom-button"
    :class="customClass"
    elevation="0"
    :outlined="outlined"
    :text="text"
    :small="small || (cSmall ? $vuetify.breakpoint.mdAndDown : false)"
    :x-small="xSmall"
    :block="block"
    :large="large"
    :width="width"
    :height="height"
  >
    <span
      class="text-transform-none custom-button-text"
      :style="text ? `color:${color}` : ``"
    >
      <slot name="content">
        {{ $t(label) }}
      </slot>
    </span>
  </v-btn>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    label: {
      type: String,
      required: false,
      default: "Submit",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    customClass: {
      type: String,
      required: false,
      default: "",
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    cSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: "",
    },
    height: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style scoped>
.text-transform-none {
  text-transform: none;
}

.custom-button {
  border-radius: 7px;
  height: 41px;
  background-color: transparent;
}
.custom-button-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #f7f7f7;
}
</style>
