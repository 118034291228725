/*
 | =========================
 | This File is for shared routes
 | =========================
 */

const SharedRoutes = [
  {
    path: "schedules",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        name: "schedules",
        path: "",
        component: () => import("@/pages/Schedules"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
    ],
  },
  {
    path: "services",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        name: "services",
        path: "",
        component: () => import("@/pages/Services"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "services.add",
        path: "add",
        component: () => import("@/pages/AddService"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "service",
        path: "service/:id",
        component: () => import("@/pages/Service"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
    ],
  },
  {
    path: "employees",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        name: "employees",
        path: "",
        component: () => import("@/pages/Employees"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "employees.add",
        path: "add",
        component: () => import("@/pages/AddEmployee"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "employee",
        path: ":id",
        component: () => import("@/pages/Employee"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "employee.edit",
        path: ":id/edit",
        component: () => import("@/pages/AddEmployee"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
    ],
  },
  {
    path: "departments",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        name: "departments",
        path: "",
        component: () => import("@/pages/Departments"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "department.add",
        path: "add",
        component: () => import("@/pages/AddDepartment"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "department",
        path: ":id",
        component: () => import("@/pages/Department"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "department.edit",
        path: ":id/edit",
        component: () => import("@/pages/AddDepartment"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
    ],
  },
  {
    path: "wards",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        name: "wards",
        path: "",
        component: () => import("@/pages/Wards"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "ward.add",
        path: "add",
        component: () => import("@/pages/AddWard"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "ward",
        path: ":id",
        component: () => import("@/pages/Ward"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "ward.edit",
        path: ":id/edit",
        component: () => import("@/pages/AddWard"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
    ],
  },
  {
    path: "reports",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        name: "reports",
        path: "",
        component: () => import("@/pages/Reports"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
    ],
  },
  {
    path: "settings",
    component: () => import("@/pages/Settings"),
    redirect: { name: "settings.profile" },
    children: [
      {
        name: "settings",
        path: "profile",
        component: () => import("@/pages/Profile"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "settings.service",
        path: "service",
        component: () => import("@/pages/ServiceSettings.vue"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "settings.notification",
        path: "notification",
        component: () => import("@/pages/Notification"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
      {
        name: "settings.shift",
        path: "shift",
        component: () => import("@/pages/Shift"),
        meta: {
          reqAuth: true,
          type: 1,
        },
      },
    ],
  },
];

export default SharedRoutes;
