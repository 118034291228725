var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { height: "60px", flat: "", app: "" } },
    [
      _c(
        "v-layout",
        {
          attrs: {
            wrap: "",
            "justify-start": "",
            "fill-height": "",
            "px-4": "",
          },
        },
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-flex",
                { attrs: { xs1: "", "align-self-center": "" } },
                [
                  _c(
                    "v-icon",
                    { attrs: { size: "20" }, on: { click: _vm.showMenu } },
                    [_vm._v("mdi-menu")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-flex",
                {
                  attrs: {
                    xs9: "",
                    sm8: "",
                    "align-self-center": "",
                    "text-left": "",
                  },
                },
                [_c("AppBreadCrumbs")],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-flex",
            { attrs: { xs1: "", "align-self-center": "", "text-right": "" } },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "nudge-bottom": "10",
                    "offset-y": "",
                    rounded: "xl",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-img",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    src: _vm.notify.icon,
                                    height: _vm.notify.size,
                                    contain: "",
                                  },
                                  on: { click: _vm.showNotification },
                                },
                                "v-img",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("Notifications", { ref: "notifications" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            {
              attrs: {
                xs6: "",
                sm3: "",
                lg2: "",
                "align-self-center": "",
                "text-right": "",
              },
            },
            [_c("AccountMenu")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }