<template>
  <v-overlay :value="getLoadingState" opacity=".3" z-index="1000">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppLoading",
  computed: {
    ...mapGetters({
      loading: "app/getLoading",
    }),
    getLoadingState() {
      return this.loading;
    },
  },
};
</script>

<style scoped></style>
