/* This File is for {purpose} */

<template>
  <v-navigation-drawer
    v-model="showMenu"
    :mini-variant="mini"
    :temporary="!defSidebar"
    :permanent="defSidebar"
    fixed
    app
    width="220px"
  >
    <template v-slot:prepend>
      <v-layout wrap justify-start>
        <v-flex xs12 pa-4>
          <!--          <v-img-->
          <!--            src="@/assets/logo2.jpg"-->
          <!--            height="25"-->
          <!--            contain-->
          <!--            @click="$router.push({ name: 'home' })"-->
          <!--          ></v-img>-->
          <v-img src="@/assets/logo.svg" height="25" contain></v-img>
        </v-flex>
      </v-layout>
    </template>
    <v-list nav dense>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        class="nav-item"
      >
        <template v-for="item in navItems">
          <v-list-item
            :key="item.title"
            link
            :to="{ name: item.route, query: {} }"
            class="ma-2 py-1"
            :class="activeLink(item) ? 'primary-background' : ''"
          >
            <v-list-item-icon>
              <v-img :src="imageRenderer(item)" height="30px" contain></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
    <v-layout wrap justify-start pa-8 v-if="$route.name === 'schedules'">
      <v-flex xs12 align-self-center py-2>
        <v-avatar color="success" size="9"></v-avatar>
        <span class="pl-4 subtitle-1">{{ $t("table.available") }}</span>
      </v-flex>
      <v-flex xs12 align-self-center py-2>
        <v-avatar color="error" size="9"></v-avatar>
        <span class="pl-4 subtitle-1">{{ $t("table.absent") }}</span>
      </v-flex>
      <v-flex xs12 align-self-center py-2>
        <v-avatar color="#3278ff" size="9"></v-avatar>
        <span class="pl-4 subtitle-1">{{ $t("table.accepted") }}</span>
      </v-flex>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import navLinks from "@/constants/app_nav_links.json";

export default {
  name: "Sidebar",
  data: () => ({
    selectedItem: 0,
    mini: false,
    navLinks: navLinks,
  }),
  created() {
    this.$store.commit("app/setSidebarItems", navLinks[this.getUserRole]);
  },
  computed: {
    ...mapGetters({
      getNavItems: "app/getSidebarItems",
      getAppMenu: "app/getAppMenu",
      userRole: "app/getUserRole",
    }),
    defSidebar() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    showMenu: {
      get() {
        return this.getAppMenu;
      },
      set(val) {
        this.$store.commit("app/setAppMenu", val);
      },
    },
    getUserRole() {
      return this.userRole.value;
    },
    navItems() {
      return this.getNavItems;
    },
  },
  methods: {
    activeLink(item) {
      const route = this.$route.name.split(".");
      return route.includes(item.route);
    },
    imageRenderer(item) {
      const route = this.$route.name.split(".");
      if (route.includes(item.route))
        return require(`@/assets/icons/w-${item.icon}.svg`);
      return require(`@/assets/icons/${item.icon}.svg`);
    },
  },
};
</script>

<style scoped lang="scss">
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 5px;
}
.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: 7px;
}
.nav-item ::v-deep .v-list-item__title {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: -0.02em !important;
  color: #081734 !important;
  //color: red !important;
}
.primary-background ::v-deep .v-list-item__title {
  color: white !important;
}
.primary-background {
  background-color: #3278ff !important;
  color: #ffffff !important;
}
</style>
