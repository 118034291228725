var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        "mini-variant": _vm.mini,
        temporary: !_vm.defSidebar,
        permanent: _vm.defSidebar,
        fixed: "",
        app: "",
        width: "220px",
      },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function () {
            return [
              _c(
                "v-layout",
                { attrs: { wrap: "", "justify-start": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "pa-4": "" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/logo.svg"),
                          height: "25",
                          contain: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            {
              staticClass: "nav-item",
              attrs: { color: "primary" },
              model: {
                value: _vm.selectedItem,
                callback: function ($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem",
              },
            },
            [
              _vm._l(_vm.navItems, function (item) {
                return [
                  _c(
                    "v-list-item",
                    {
                      key: item.title,
                      staticClass: "ma-2 py-1",
                      class: _vm.activeLink(item) ? "primary-background" : "",
                      attrs: { link: "", to: { name: item.route, query: {} } },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.imageRenderer(item),
                              height: "30px",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(_vm.$t(item.title)) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.$route.name === "schedules"
        ? _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pa-8": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", "align-self-center": "", "py-2": "" } },
                [
                  _c("v-avatar", { attrs: { color: "success", size: "9" } }),
                  _c("span", { staticClass: "pl-4 subtitle-1" }, [
                    _vm._v(_vm._s(_vm.$t("table.available"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", "align-self-center": "", "py-2": "" } },
                [
                  _c("v-avatar", { attrs: { color: "error", size: "9" } }),
                  _c("span", { staticClass: "pl-4 subtitle-1" }, [
                    _vm._v(_vm._s(_vm.$t("table.absent"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", "align-self-center": "", "py-2": "" } },
                [
                  _c("v-avatar", { attrs: { color: "#3278ff", size: "9" } }),
                  _c("span", { staticClass: "pl-4 subtitle-1" }, [
                    _vm._v(_vm._s(_vm.$t("table.accepted"))),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }