import ApiService from "./api.service";

class AppService {
  constructor() {
    this.request = ApiService;
  }

  getQualifications = () => {
    return this.request.get(`/qualifications`);
  };

  getDepartments = ({ per_page, page }) => {
    const params = { per_page, page };
    return this.request.get(`/hospital-admin/department`, { params });
  };

  getDepartment = (id) => {
    return this.request.get(`/hospital-admin/department/${id}`);
  };

  getTimezones = () => {
    return this.request.get(`/timezones`);
  };

  getNotifications = ({ per_page, page, type }) => {
    const params = { per_page, page, type, noLoading: true };
    return this.request.get(`/hospital-admin/notifications`, { params });
  };
}

export default new AppService();
