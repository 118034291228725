var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "custom-button",
      class: _vm.customClass,
      attrs: {
        color: _vm.color,
        ripple: false,
        elevation: "0",
        outlined: _vm.outlined,
        text: _vm.text,
        small:
          _vm.small || (_vm.cSmall ? _vm.$vuetify.breakpoint.mdAndDown : false),
        "x-small": _vm.xSmall,
        block: _vm.block,
        large: _vm.large,
        width: _vm.width,
        height: _vm.height,
      },
      on: { click: _vm.submit },
    },
    [
      _c(
        "span",
        {
          staticClass: "text-transform-none custom-button-text",
          style: _vm.text ? `color:${_vm.color}` : ``,
        },
        [
          _vm._t("content", function () {
            return [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }