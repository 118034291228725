/*
 * Global configuration for vuex
 */
import Vue from "@/plugins/subscriber";
import Vuex from "vuex";

/*
 * Available store modules
 */
import app from "@/store/modules/App";
import hospital from "@/store/modules/Hospital";
import employee from "@/store/modules/Employee";
import admin from "@/store/modules/Admin";
Vue.use(Vuex);

/*
 * Registering store modules
 */
export default new Vuex.Store({
  modules: {
    app: app,
    admin: admin,
    hospital: hospital,
    employee: employee,
  },
  strict: true,
});
