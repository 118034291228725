import ApiService from "./api.service";

class AuthService {
  constructor() {
    this.request = ApiService;
  }

  login = (formData) => {
    return this.request.post(`/login`, formData);
  };

  logout = (formData) => {
    return this.request.post(`/logout`, formData);
  };

  forgotPassword = (formData) => {
    return this.request.post(`/forgot-password`, formData);
  };

  resetPassword = ({ expires, id, old_sign, signature, ...formData }) => {
    return this.request.post(
      `/password-reset?expires=${expires}&id=${id}&old_sign=${old_sign}&signature=${signature}`,
      formData
    );
  };

  changePassword = (formData) => {
    return this.request.post(`/password-reset`, formData);
  };
}

export default new AuthService();
