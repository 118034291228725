/* This File is for {purpose} */

<template>
  <v-dialog
    v-model="curValue"
    width="500"
    persistent
    content-class="brd-radius"
  >
    <v-card rounded="xl">
      <v-layout wrap justify-start pa-4>
        <v-flex xs12>
          <v-layout wrap justify-start>
            <v-flex xs2>
              <v-img
                src="@/assets/icons/success-add.svg"
                height="50px"
                contain
              />
            </v-flex>
            <v-flex xs10 class="title-1" align-self-center>
              {{ $t(title) }}
            </v-flex>

            <v-flex xs12 v-if="description">
              <v-layout wrap justify-end>
                <V-flex xs10>
                  <span class="subtitle-2">
                    {{ $t(description) }}
                  </span>
                </V-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap justify-end>
                <V-flex xs10>
                  <slot name="content"></slot>
                </V-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 py-4>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 text-right>
              <span class="px-4" v-if="type !== 'edit'">
                <AppButton
                  v-if="showDuplicate"
                  @submit="duplicate"
                  color="#A6AEBE"
                  custom-class="pa-5"
                  text
                >
                  <template #content>
                    <v-icon size="14px" color="#a6aebe">
                      mdi-content-copy
                    </v-icon>
                    <span style="color: #a6aebe">
                      {{ $t("words.duplicate") }}
                    </span>
                  </template>
                </AppButton>
                <AppButton
                  @submit="cancel"
                  color="#A6AEBE"
                  custom-class="pa-5"
                  text
                >
                  <template #content>
                    <v-icon size="14px" color="#a6aebe">mdi-plus</v-icon>
                    <span style="color: #a6aebe">
                      {{ $t("messages.add_another") }}
                    </span>
                  </template>
                </AppButton>
              </span>

              <span class="pl-4">
                <AppButton custom-class="pa-5" @submit="submit">
                  <template #content> {{ $t("buttons.done") }} </template>
                </AppButton>
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SuccessConfirmation",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Success",
    },
    type: {
      type: String,
      default: "edit",
    },
    item: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
    description: {
      type: String,
      default: null,
    },
    showDuplicate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    curValue: {
      get() {
        return this.value ?? false;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    },
    duplicate() {
      this.$emit("duplicate");
    },
  },
};
</script>
