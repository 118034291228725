import Helpers from "@/libs/helper/components/helper-functions";
import HospitalService from "@/services/hospital.service";
import EmployeeService from "@/services/employee.service";

const actions = {
  async getHospitalList(context) {
    const result = await HospitalService.getHospitalList();
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setHospitals", response.data?.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getHospital(context, payload) {
    const result = await HospitalService.getHospital(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async addHospital(context, payload) {
    const result = await HospitalService.addHospital(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async editHospital(context, payload) {
    const result = await HospitalService.editHospital(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async uploadEmployees(context, payload) {
    const result = await HospitalService.uploadEmployees(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async addEmployee(context, payload) {
    const result = await HospitalService.addEmployee(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response);
      });
  },

  async getEmployeeList(context, payload) {
    const result = await EmployeeService.getEmployeeList(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setEmployees", response.data?.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getEmployee(context, payload) {
    const result = await HospitalService.getEmployee(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setEmployee", response.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async editEmployee(context, payload) {
    const result = await HospitalService.editEmployee(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response);
      });
  },

  async deleteEmployee(context, payload) {
    const result = await HospitalService.deleteEmployee(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
  async restoreEmployee(context, payload) {
    const result = await HospitalService.restoreEmployee(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getEmployeeSchedules(context, payload) {
    const result = await HospitalService.getEmployeeSchedules(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        const data = response.data?.data?.map((item) => {
          let schedules = {};
          Object.keys(item.schedules).forEach((itm) => {
            schedules[itm] = item.schedules[itm].schedules.sort(
              (a, b) => a.shift_id - b.shift_id
            );
          });
          return {
            name: item.first_name + " " + item.last_name,
            present: item.no_of_present,
            absent: item.no_of_absent,
            assigned: item.total_assigned,
            id: item.id,
            schedules: schedules,
          };
        });
        context.commit("setEmployeeSchedules", data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getEmployeeSchedule(context, payload) {
    const result = await HospitalService.getEmployeeSchedule(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setEmployeeSchedule", response.data?.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async addDepartment(context, payload) {
    const result = await HospitalService.addDepartment(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getDepartment(context, payload) {
    const result = await HospitalService.getDepartment(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async editDepartment(context, payload) {
    const result = await HospitalService.editDepartment(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async deleteDepartment(context, payload) {
    const result = await HospitalService.deleteDepartment(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getShifts(context) {
    const result = await HospitalService.getShifts();
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setShifts", response.data?.shifts);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async editShifts(context, payload) {
    const result = await HospitalService.editShifts(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setShifts", response.data?.shifts);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async addService(context, payload) {
    const result = await HospitalService.addService(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getServiceList(context, payload) {
    const result = await HospitalService.getServiceList(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setServices", response.data?.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getService(context, payload) {
    const result = await HospitalService.getService(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setService", response?.data);
        return Promise.resolve(response?.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
  async deleteService(context, payload) {
    const result = await HospitalService.deleteService(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getEmployeeAssignments(context, payload) {
    if (payload.download) payload.per_page = "all";
    const result = await HospitalService.getEmployeeAssignments(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        if (!payload.download)
          context.commit("setEmployeeAssignments", response.data?.data);
        else {
          return Helpers.fileDownloadWithBlob(
            response,
            payload.fileName ?? "Employee.xlsx"
          );
        }
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getServiceShifts(context, payload) {
    const result = await HospitalService.getServiceShifts(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setServiceShifts", response.data?.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getWards(context, payload) {
    const result = await HospitalService.getWards(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setWards", response.data?.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async addWard(context, payload) {
    const result = await HospitalService.addWard(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async getWard(context, payload) {
    const result = await HospitalService.getWard(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setWard", response.data);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
  async getWardShifts(context, payload) {
    if (payload.download) payload.per_page = "all";
    const result = await HospitalService.getWardShifts(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        if (!payload.download)
          context.commit("setWardShifts", response.data.data);
        else {
          return Helpers.fileDownloadWithBlob(
            response,
            payload.fileName ?? "Ward.xlsx"
          );
        }
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async editWard(context, payload) {
    const result = await HospitalService.editWard(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async deleteWard(context, payload) {
    const result = await HospitalService.deleteWard(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async deleteShift(context, payload) {
    const result = await HospitalService.deleteShift(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async activateShift(context, payload) {
    const result = await HospitalService.activateShift(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },

  async deactivateShift(context, payload) {
    const result = await HospitalService.deactivateShift(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
  async serviceEmployeeCount(context, payload) {
    const result = await HospitalService.serviceEmployeeCount(payload);
    return Helpers.checkResponse(result)
      .then((response) => {
        context.commit("setServiceEmployeeCount", response.data.employee_count);
        return Promise.resolve(response.data.employee_count);
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
};

export default actions;
