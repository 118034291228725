var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "", "content-class": "brd-radius" },
      model: {
        value: _vm.curValue,
        callback: function ($$v) {
          _vm.curValue = $$v
        },
        expression: "curValue",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { rounded: "xl" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pa-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-start": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs2: "" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/icons/success-add.svg"),
                              height: "50px",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "title-1",
                          attrs: { xs10: "", "align-self-center": "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("messages.restore.restore")) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.item.name) + " ? "),
                        ]
                      ),
                      _c("V-flex", { attrs: { xs8: "", "offset-2": "" } }, [
                        _c("span", { staticClass: "subtitle-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("messages.restore.sub_title")) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "py-4": "" } },
                        [_c("v-divider")],
                        1
                      ),
                      _c("v-flex", { attrs: { xs12: "", "text-right": "" } }, [
                        _c(
                          "span",
                          { staticClass: "px-4" },
                          [
                            _c("AppButton", {
                              attrs: {
                                color: "#A6AEBE",
                                "custom-class": "pa-5",
                                text: "",
                              },
                              on: { submit: _vm.cancel },
                              scopedSlots: _vm._u([
                                {
                                  key: "content",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#a6aebe" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("buttons.cancel")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "pl-4" },
                          [
                            _c("AppButton", {
                              attrs: {
                                color: "primary",
                                "custom-class": "pa-5",
                                label: "messages.restore.restore",
                              },
                              on: { submit: _vm.submit },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }