/* This File is for {purpose} */

<template>
  <v-dialog
    v-model="curValue"
    width="500"
    persistent
    content-class="brd-radius"
  >
    <v-card rounded="xl">
      <v-layout wrap justify-start pa-4>
        <v-flex xs12>
          <v-layout wrap justify-start>
            <v-flex xs2>
              <v-img
                src="@/assets/icons/success-add.svg"
                height="50px"
                contain
              />
            </v-flex>
            <v-flex xs10 class="title-1" align-self-center>
              {{ $t("messages.restore.restore") }} <br />
              {{ item.name }} ?
            </v-flex>
            <V-flex xs8 offset-2>
              <span class="subtitle-2">
                {{ $t("messages.restore.sub_title") }}
              </span>
            </V-flex>
            <v-flex xs12 py-4>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 text-right>
              <span class="px-4">
                <AppButton
                  color="#A6AEBE"
                  custom-class="pa-5"
                  text
                  @submit="cancel"
                >
                  <template #content>
                    <span style="color: #a6aebe">
                      {{ $t("buttons.cancel") }}
                    </span>
                  </template>
                </AppButton>
              </span>

              <span class="pl-4">
                <AppButton
                  color="primary"
                  custom-class="pa-5"
                  @submit="submit"
                  label="messages.restore.restore"
                />
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ActionConfirmation",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
  },
  computed: {
    curValue: {
      get() {
        return this.value ?? false;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
