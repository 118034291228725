var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.getToasts, function (item, i) {
        return [
          _c(
            "v-snackbar",
            {
              key: "toast-" + i,
              attrs: { color: _vm.getColor(item), bottom: "", right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "action",
                    fn: function ({ attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._b(
                            {
                              attrs: { color: "white", text: "" },
                              on: {
                                click: function ($event) {
                                  item.show = false
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: item.show,
                callback: function ($$v) {
                  _vm.$set(item, "show", $$v)
                },
                expression: "item.show",
              },
            },
            [_vm._v(" " + _vm._s(item.message) + " ")]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }