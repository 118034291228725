<template>
  <AppButton>
    <template #content>
<!--      <json-excel :data="items" :fields="headings" :name="name" type="xlsx">-->
        {{ $t("table.export_data") }}
<!--      </json-excel>-->
    </template>
  </AppButton>
</template>
<script>
// import JsonExcel from "vue-json-excel";
export default {
  name: "AppExcelExport",
  components: {
    // JsonExcel,
  },
  props: {
    headings: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: false,
    },
    name: {
      type: String,
      required: false,
      default: "CareKraft.xlsx",
    },
  },
  data: () => ({
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
};
</script>
<style scoped></style>
