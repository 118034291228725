const getters = {
  getEmployees: (state) => {
    return state.employees;
  },
  getEmployee: (state) => {
    return state.employee;
  },
};

export default getters;
