import Vue from "@/plugins/subscriber";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

// Layout Files
import BaseLayout from "@/layouts/BaseLayout";

// Children Route Files
import AuthRoutes from "@/router/auth";
import SharedRoutes from "@/router/shared";
import DashboardLayout from "@/layouts/DashboardLayout";
import AdminRoutes from "@/router/admin";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: BaseLayout,
      children: [
        {
          name: "home",
          path: "",
          component: () => import("@/pages/Home"),
          redirect: {
            name: "login",
          },
        },
        ...AuthRoutes,
      ],
    },
    {
      path: "/",
      component: DashboardLayout,
      children: SharedRoutes,
    },
    {
      path: "/admin/",
      component: DashboardLayout,
      children: AdminRoutes,
    },
    // Root level pages
    {
      name: "error",
      path: "/error/:code",
      component: () => import("@/pages/Error"),
    },
    {
      path: "*",
      redirect: "/error/404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.reqAuth) &&
    !store.getters["app/isLoggedIn"]
  ) {
    next({ name: "login" });
    return;
  }
  if (
    to.matched.some((route) => route.name === "login") &&
    store.getters["app/isLoggedIn"]
  ) {
    if (store.getters["app/getProfile"].role === 0) next({ name: "hospitals" });
    else next({ name: "services" });
    return;
  }
  next();
});
export default router;
