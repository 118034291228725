/*
 | =========================
 | This File is for admin routes
 | =========================
 */

const AdminRoutes = [
  {
    name: "dashboard",
    path: "dashboard",
    component: () => import("@/pages/admin/Dashboard"),
    meta: {
      reqAuth: true,
      type: 0,
    },
  },

  {
    path: "hospitals",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        name: "hospitals",
        path: "",
        component: () => import("@/pages/admin/Hospitals"),
        meta: {
          reqAuth: true,
          type: 0,
        },
      },
      {
        name: "hospitals.add",
        path: "add",
        component: () => import("@/pages/admin/AddHospital"),
        meta: {
          reqAuth: true,
          type: 0,
        },
      },
      {
        name: "hospital.edit",
        path: ":id/edit",
        component: () => import("@/pages/admin/AddHospital"),
        meta: {
          reqAuth: true,
          type: 0,
        },
      },
    ],
  },
  {
    path: "settings",
    component: () => import("@/pages/Settings"),
    redirect: { name: "settings.profile" },
    children: [
      {
        name: "admin.settings",
        path: "profile",
        component: () => import("@/pages/Profile"),
        meta: {
          reqAuth: true,
          type: 0,
        },
      },
      {
        name: "admin.settings.service",
        path: "service",
        component: () => import("@/pages/ServiceSettings.vue"),
        meta: {
          reqAuth: true,
          type: 0,
        },
      },
      {
        name: "admin.settings.notification",
        path: "notification",
        component: () => import("@/pages/Notification"),
        meta: {
          reqAuth: true,
          type: 0,
        },
      },
    ],
  },
];

export default AdminRoutes;
