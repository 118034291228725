const mutations = {
  setSidebarItems: (state, payload) => {
    state.sidebarItems = payload;
  },

  //Set BreadCrumb navigation
  setBreadCrumbs: (state, payload) => {
    state.breadCrumbs = payload;
  },

  // Set App menu to on or off depending on screen size and menu switch
  setAppMenu: (state, payload) => {
    state.appMenu = payload;
  },

  setLang: (state, payload) => {
    state.lang = payload;
  },

  setLogin: (state, payload) => {
    state.isLoggedIn = payload;
  },

  setProfile: (state, payload) => {
    state.profile = payload;
  },

  setToasts: (state, payload) => {
    state.toasts = payload;
  },

  setQualifications: (state, payload) => {
    state.qualifications = payload;
  },

  setDepartments: (state, payload) => {
    state.departments = payload;
  },

  setDepartment: (state, payload) => {
    state.department = payload;
  },

  setTimezones: (state, payload) => {
    state.timezones = payload;
  },

  setLoading(state, isLoading) {
    if (isLoading) {
      state.fetchCount++;
      state.isLoading = true;
    } else if (state.fetchCount > 0) {
      state.fetchCount--;
      state.isLoading = state.fetchCount > 0;
    }
  },

  setNotify: (state, payload) => {
    state.notify = payload;
  },

  setNotifications: (state, payload) => {
    state.notifications = payload;
  },

  setFcmToken: (state, payload) => {
    state.fcmToken = payload;
  },
};

export default mutations;
