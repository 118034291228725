/* This File is for base layout */

<template>
  <v-layout wrap justify-start>
    <v-flex xs12>
      <router-view></router-view>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "BaseLayout",
};
</script>

<style scoped></style>
